@use "sass:math";

$light-teal: #D1DCDB;
$dark-teal: #18514D;
$offwhite: #F2F2F2;
$black: #1A1A1A;
$darkgreen: #030d0a;

$grid-width: 1200px;
$columns: 12;
$column-width: math.div($grid-width, $columns);

@mixin mobile-section-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}

@mixin section-padding {
  padding-top: 80px;
  padding-bottom: 90px;
}

@mixin grid($cols) {
  display: grid;
  grid-template-columns: repeat($cols, 1fr);

  & > * {
    margin: 0;
  }
}

@mixin mobile-rhythm {
  > * {
    margin-bottom: 32px;

    > * {
      margin-bottom: 16px;
    }
  }
}

@mixin acumin {
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
}

@mixin liberation {
  font-family: liberation-serif, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
}

html {
  height: 100%;
  font-size: 85%;

  @media screen and (min-width: 860px) {
    font-size: 100%;
  }
}

body {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  min-height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  @include liberation;
  color: $black;
}

header, section, footer {
  padding-left: 20px;
  padding-right: 20px;
}

header {
  .serif-large {
    max-width: 674px;
  }
}

h1 {
  text-transform: uppercase;
  @include acumin;
}

h2 {
  margin-top: 0;
  font-size: 1.125em;
  @include acumin;
  text-transform: uppercase;
  color: $dark-teal;
  line-height: math.div(22, 18);
}

h3 {
  margin: 0;
  @include acumin;
  font-size: 1.5em;
  text-transform: uppercase;
  line-height: math.div(36, 24);
}

p, ul, ol {
  font-size: 1.5em;
  line-height: math.div(34, 24);
}

a {
  color: $black;

  &:hover {
    color: $dark-teal;
  }
}

.serif-large {
  font-size: 2em;
  font-weight: 400;
  line-height: math.div(48, 36);
}

.serif-x-large {
  font-size: 3em;
  font-weight: 400;
  line-height: math.div(48, 36);
  text-transform: capitalize;
}

.btn {
  padding: 13px 21px;
  @include acumin;
  font-size: 1.3125em;
  text-transform: uppercase;
  line-height: 1;
  color: white;
  background-color: transparent;
  border: 1px solid white;
  cursor: pointer;
  outline: none;
  text-decoration: none;

  &:hover {
    background-color: white;
    color: $dark-teal;
  }
  
  &.btn-inverse {
    display: inline-block;
    background-color: $dark-teal;
    color: white;
    &:hover {
      background-color: white;
      color: $dark-teal;
      border-color: $dark-teal;
    }
  }
}

.row {
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

footer {
  @include mobile-section-padding;
  @include mobile-rhythm;
  color: white;
  background-color: $dark-teal;

  p {
    margin: 0 0 0.5em 0;
    font-size: 1.5em;
    font-style: normal;
    line-height: 1;
  }

  a {
    margin-bottom: 3px;
    color: white;
    text-decoration: none;
    border-bottom: 2px solid transparent;

    &:hover {
      color: white;
      border-bottom: 2px solid white;
    }
  }

  h2 {
    color: white;
  }

  .logo {
    width: 75%;
    max-width: 269px;
  }

  @media screen and (min-width: 1200px) {
    @include section-padding;
  }
}

body.index {
  header {
    min-height: 389px;
    padding-top: 60px;
    padding-bottom: 60px;
    background-image: 
      url(../images/shutterstock_1136423609.jpg),
      url(../images/shutterstock_1136423609.jpg),
      url(../images/shutterstock_1136423609.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom 0px right 0px, bottom 0px right 0px, bottom 0 right -70px;
    transition: background-position 1s ease;
    background-color: $darkgreen;
    color: white;

    @media screen and (min-width: 1200px) {
      padding-top: 160px;
      padding-bottom: 180px;
    }
  }

  @media screen and (min-width: 1200px) {
    &.loaded header {
      background-image: 
        url(../images/shutterstock_1136423609.jpg),
        url(../images/shutterstock_679090132-cropped.jpg),
        url(../images/shutterstock_222719944-cropped.jpg);
      background-size: cover, contain, contain;
      background-position: bottom 0px right 447px, bottom 0px right 210px, bottom 0px right -70px;
      background-repeat: no-repeat;
    }
  }

  header h1 {
    font-size: 0px;
    color: rgba(0, 0, 0, 0);

    img {
      max-width: 100%;
      margin-left: -20px;

      @media screen and (min-width: 860px) {
        width: unset;
        margin-left: -39px;
      }

      @media screen and (min-width: 1200px) {
        
      }
    }
  }

  .people, .stay-in-touch {
    @include mobile-section-padding;
    @include mobile-rhythm;
    background-color: $offwhite;

    *:last-child {
      margin-bottom: 0;
    }

    .person {
      p {
        margin: 0;
      }
    }

    @media screen and (min-width: 1200px) {
      @include section-padding;
    }
  }

  .stay-in-touch {
    > * {
      grid-column: 1 / 9;
    }

    h2, p {
      margin-bottom: 0;
    }
  }

  .our-focus-areas {
    @include mobile-section-padding;
    background-color: $light-teal;

    h3 {
      margin-bottom: 1em;
    }

    div {
      margin-top: 3em;
      // opacity: 0;
      transition: opacity 1s ease;

      // &.visible {
      //   opacity: 1;
      // }

      > p:last-child {
        margin-bottom: 0;
      }

      @media screen and (min-width: 1200px) {
        margin-top: 0;
      }
    }

    div:last-child h3 {
      margin-bottom: 0;
    }

    .contact-email {
      font-size: 70%;

      @media screen and (min-width: 860px) {
        font-size: 100%;
      }
    }

    @media screen and (min-width: 1200px) {
      @include section-padding;
    }
  }
}

body.team {
  header {
    display: flex;
    align-items: flex-end;

    h1 img {
      width: 175px;
      vertical-align: bottom;
    }
    h2 {
      margin-bottom: 20px;
      margin-left: 2em;

      a {
        color: $dark-teal;
      }
    }
  }

  section {
    padding-bottom: 4em;
    background-color: $offwhite;

    > * {
      grid-column: 1 / -1;
    }

    h2.serif-x-large {
      @media screen and (min-width: 860px) {
        margin-top: 0.5em;
      } 
    }

    h3 {
      font-size: 1.8em;
      line-height: 1.3;
      text-transform: capitalize;
    }

    .accordion {
      margin-bottom: 4em;

      &.accordion-image {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 768px) {
          flex-direction: row;
        }
      }

      .image {
        margin-bottom: 1em;

        @media screen and (min-width: 768px) {
          margin-right: 30px;
          margin-bottom: 0;
        }
        
        img {
          max-width: 100%;

          @media screen and (min-width: 768px) {
            max-width: 300px;
          }
          @media screen and (min-width: 1200px) {
            max-width: 400px;
          }
        }
      }

      @media screen and (min-width: 860px) {
        margin-bottom: 1em;
      } 
    }

    .accordion + h2 {
      margin-top: 2em;
    }

    .accordion--content {
      height: auto;
      overflow: hidden;
      transition: height .4s ease-out;
    }

    .serif-large {
      margin-bottom: 0;
    }

    .serif-large + p {
      margin-top: 10px;
    }

    .accordion--content p {
      margin-top: 0;
    }

    .accordion--disclosure {
      margin-top: 0;
      text-decoration: underline;
      font-weight: bold;
      cursor: pointer;
    }

    @media screen and (max-width: 1199px) {
      padding-top: 0px;
      padding-bottom: 80px;

      h2:first-of-type {
        padding-top: 1em;
        margin-top: 0;
      }

      h2.staff {
        margin-top: 3em;
      }

      h2.board {
        margin-top: 4em;
      }

      .row {
        margin-top: 2em;
      }
    }

    @media screen and (min-width: 1200px) {
      padding-top: 40px;
    }
  }
}

.about {
  @include mobile-section-padding;  
  background-color: $offwhite;

  > *:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1200px) {
    padding-top: 120px;
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 860px) {
  header, section, footer {
    padding-left: 120px;
    padding-right: 120px;
  }

  .people {
    @include grid(4);
    grid-gap: 24px;

    h2 {
      grid-column: span 4;
    }

    .staff {
      grid-column: span 2;
      @include grid(1);
      grid-template-rows: repeat(3, min-content);
      grid-gap: 24px;
      margin-bottom: 0 !important;
    }

    .board {
      grid-column: 3 / span 2;
      @include grid(1);
      grid-gap: 24px;
    }
  }

  footer {
    @include grid(4);
    grid-gap: 24px;

    h2 {
      grid-column: span 4;
    }

    address {
      grid-column: span 2;
    }

    .contact {
      grid-column: 3 / span 2;
    }

    .logo {
      grid-column: span 2;
    }
  }
}

@media screen and (min-width: 1200px) {
  section {
    @include grid($columns);
    grid-gap: 48px;
  }

  .about * {
    grid-column: 3 / span 8;
  }

  .our-focus-areas {
    h2, .serif-large {
      grid-column: span 12;
    }
    div {
      grid-column: span 8;
      // padding: 0 $column-width / 2;
    }
  }

  .people {
    @include grid($columns);
    grid-gap: 48px;

    h2 {
      grid-column: span 12;
    }

    .person {
      grid-column: span 4;
    }

    .staff {
      grid-column: span 4;
      @include grid(4);
    }

    .board {
      grid-column: 5 / span 8;
      @include grid(8);

      h3 {
        grid-column: span 8;
      }
    }
  }

  footer {
    @include grid($columns);
    grid-gap: 48px;

    h2 {
      grid-column: span 2;
    }

    address {
      grid-column: 3 / span 4;
    }

    .contact {
      grid-column: 7 / span 4;
    }
  }
}

body #mc_embed_signup {
  background: none;
  @include acumin;

  form {
    padding: 0;
    margin-top: 2em;
    text-align: left;

    @media screen and (min-width: 860px) {
      margin-top: 0;
    }

    input.email, .button {
      height: 48px;
      border-radius: 0;
    }
    .button {
      padding: 0 22px;
      font-size: 1em;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      background-color: $dark-teal;
      border: 1px solid $dark-teal;
      color: white;
      transition: none;
      &:hover {
        background-color: white;
        color: $dark-teal;
        border-color: $dark-teal;
      }

      @media screen and (min-width: 860px) {
        margin-left: 15px;
      }
    }
  }
}

.meet {
  font-weight: bold;
  text-transform: uppercase;
}