html {
  height: 100%;
  font-size: 85%;
}

@media screen and (min-width: 860px) {
  html {
    font-size: 100%;
  }
}

body {
  max-width: 1440px;
  min-height: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  color: #1a1a1a;
  flex-direction: column;
  margin: 0 auto;
  font-family: liberation-serif, serif;
  font-style: normal;
  font-weight: 400;
  display: flex;
}

header, section, footer {
  padding-left: 20px;
  padding-right: 20px;
}

header .serif-large {
  max-width: 674px;
}

h1 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-style: normal;
  font-weight: 600;
}

h2 {
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #18514d;
  margin-top: 0;
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-size: 1.125em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.22222;
}

h3 {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
}

p, ul, ol {
  font-size: 1.5em;
  line-height: 1.41667;
}

a {
  color: #1a1a1a;
}

a:hover {
  color: #18514d;
}

.serif-large {
  font-size: 2em;
  font-weight: 400;
  line-height: 1.33333;
}

.serif-x-large {
  text-transform: capitalize;
  font-size: 3em;
  font-weight: 400;
  line-height: 1.33333;
}

.btn {
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  outline: none;
  padding: 13px 21px;
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-size: 1.3125em;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
}

.btn:hover {
  color: #18514d;
  background-color: #fff;
}

.btn.btn-inverse {
  color: #fff;
  background-color: #18514d;
  display: inline-block;
}

.btn.btn-inverse:hover {
  color: #18514d;
  background-color: #fff;
  border-color: #18514d;
}

.row > :first-child {
  margin-top: 0;
}

.row > :last-child {
  margin-bottom: 0;
}

footer {
  color: #fff;
  background-color: #18514d;
  padding-top: 40px;
  padding-bottom: 40px;
}

footer > * {
  margin-bottom: 32px;
}

footer > * > * {
  margin-bottom: 16px;
}

footer p {
  margin: 0 0 .5em;
  font-size: 1.5em;
  font-style: normal;
  line-height: 1;
}

footer a {
  color: #fff;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  margin-bottom: 3px;
  text-decoration: none;
}

footer a:hover {
  color: #fff;
  border-bottom: 2px solid #fff;
}

footer h2 {
  color: #fff;
}

footer .logo {
  width: 75%;
  max-width: 269px;
}

@media screen and (min-width: 1200px) {
  footer {
    padding-top: 80px;
    padding-bottom: 90px;
  }
}

body.index header {
  min-height: 389px;
  color: #fff;
  background-color: #030d0a;
  background-image: url("shutterstock_1136423609.7efae031.jpg"), url("shutterstock_1136423609.7efae031.jpg"), url("shutterstock_1136423609.7efae031.jpg");
  background-position: right 0 bottom 0, right 0 bottom 0, right -70px bottom 0;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 60px;
  transition: background-position 1s;
}

@media screen and (min-width: 1200px) {
  body.index header {
    padding-top: 160px;
    padding-bottom: 180px;
  }

  body.index.loaded header {
    background-image: url("shutterstock_1136423609.7efae031.jpg"), url("shutterstock_679090132-cropped.debfba92.jpg"), url("shutterstock_222719944-cropped.0caeb90f.jpg");
    background-position: right 447px bottom 0, right 210px bottom 0, right -70px bottom 0;
    background-repeat: no-repeat;
    background-size: cover, contain, contain;
  }
}

body.index header h1 {
  color: rgba(0, 0, 0, 0);
  font-size: 0;
}

body.index header h1 img {
  max-width: 100%;
  margin-left: -20px;
}

@media screen and (min-width: 860px) {
  body.index header h1 img {
    width: unset;
    margin-left: -39px;
  }
}

body.index .people, body.index .stay-in-touch {
  background-color: #f2f2f2;
  padding-top: 40px;
  padding-bottom: 40px;
}

body.index .people > *, body.index .stay-in-touch > * {
  margin-bottom: 32px;
}

body.index .people > * > *, body.index .stay-in-touch > * > * {
  margin-bottom: 16px;
}

body.index .people :last-child, body.index .stay-in-touch :last-child {
  margin-bottom: 0;
}

body.index .people .person p, body.index .stay-in-touch .person p {
  margin: 0;
}

@media screen and (min-width: 1200px) {
  body.index .people, body.index .stay-in-touch {
    padding-top: 80px;
    padding-bottom: 90px;
  }
}

body.index .stay-in-touch > * {
  grid-column: 1 / 9;
}

body.index .stay-in-touch h2, body.index .stay-in-touch p {
  margin-bottom: 0;
}

body.index .our-focus-areas {
  background-color: #d1dcdb;
  padding-top: 40px;
  padding-bottom: 40px;
}

body.index .our-focus-areas h3 {
  margin-bottom: 1em;
}

body.index .our-focus-areas div {
  margin-top: 3em;
  transition: opacity 1s;
}

body.index .our-focus-areas div > p:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
  body.index .our-focus-areas div {
    margin-top: 0;
  }
}

body.index .our-focus-areas div:last-child h3 {
  margin-bottom: 0;
}

body.index .our-focus-areas .contact-email {
  font-size: 70%;
}

@media screen and (min-width: 860px) {
  body.index .our-focus-areas .contact-email {
    font-size: 100%;
  }
}

@media screen and (min-width: 1200px) {
  body.index .our-focus-areas {
    padding-top: 80px;
    padding-bottom: 90px;
  }
}

body.team header {
  align-items: flex-end;
  display: flex;
}

body.team header h1 img {
  width: 175px;
  vertical-align: bottom;
}

body.team header h2 {
  margin-bottom: 20px;
  margin-left: 2em;
}

body.team header h2 a {
  color: #18514d;
}

body.team section {
  background-color: #f2f2f2;
  padding-bottom: 4em;
}

body.team section > * {
  grid-column: 1 / -1;
}

@media screen and (min-width: 860px) {
  body.team section h2.serif-x-large {
    margin-top: .5em;
  }
}

body.team section h3 {
  text-transform: capitalize;
  font-size: 1.8em;
  line-height: 1.3;
}

body.team section .accordion {
  margin-bottom: 4em;
}

body.team section .accordion.accordion-image {
  flex-direction: column;
  display: flex;
}

@media screen and (min-width: 768px) {
  body.team section .accordion.accordion-image {
    flex-direction: row;
  }
}

body.team section .accordion .image {
  margin-bottom: 1em;
}

@media screen and (min-width: 768px) {
  body.team section .accordion .image {
    margin-bottom: 0;
    margin-right: 30px;
  }
}

body.team section .accordion .image img {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  body.team section .accordion .image img {
    max-width: 300px;
  }
}

@media screen and (min-width: 1200px) {
  body.team section .accordion .image img {
    max-width: 400px;
  }
}

@media screen and (min-width: 860px) {
  body.team section .accordion {
    margin-bottom: 1em;
  }
}

body.team section .accordion + h2 {
  margin-top: 2em;
}

body.team section .accordion--content {
  height: auto;
  transition: height .4s ease-out;
  overflow: hidden;
}

body.team section .serif-large {
  margin-bottom: 0;
}

body.team section .serif-large + p {
  margin-top: 10px;
}

body.team section .accordion--content p {
  margin-top: 0;
}

body.team section .accordion--disclosure {
  cursor: pointer;
  margin-top: 0;
  font-weight: bold;
  text-decoration: underline;
}

@media screen and (max-width: 1199px) {
  body.team section {
    padding-top: 0;
    padding-bottom: 80px;
  }

  body.team section h2:first-of-type {
    margin-top: 0;
    padding-top: 1em;
  }

  body.team section h2.staff {
    margin-top: 3em;
  }

  body.team section h2.board {
    margin-top: 4em;
  }

  body.team section .row {
    margin-top: 2em;
  }
}

@media screen and (min-width: 1200px) {
  body.team section {
    padding-top: 40px;
  }
}

.about {
  background-color: #f2f2f2;
  padding-top: 40px;
  padding-bottom: 40px;
}

.about > :last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
  .about {
    padding-top: 120px;
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 860px) {
  header, section, footer {
    padding-left: 120px;
    padding-right: 120px;
  }

  .people {
    grid-gap: 24px;
    grid-template-columns: repeat(4, 1fr);
    display: grid;
  }

  .people > * {
    margin: 0;
  }

  .people h2 {
    grid-column: span 4;
  }

  .people .staff {
    grid-gap: 24px;
    grid-column: span 2;
    grid-template-rows: repeat(3, min-content);
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    margin-bottom: 0 !important;
  }

  .people .staff > * {
    margin: 0;
  }

  .people .board {
    grid-gap: 24px;
    grid-column: 3 / span 2;
    grid-template-columns: repeat(1, 1fr);
    display: grid;
  }

  .people .board > * {
    margin: 0;
  }

  footer {
    grid-gap: 24px;
    grid-template-columns: repeat(4, 1fr);
    display: grid;
  }

  footer > * {
    margin: 0;
  }

  footer h2 {
    grid-column: span 4;
  }

  footer address {
    grid-column: span 2;
  }

  footer .contact {
    grid-column: 3 / span 2;
  }

  footer .logo {
    grid-column: span 2;
  }
}

@media screen and (min-width: 1200px) {
  section {
    grid-gap: 48px;
    grid-template-columns: repeat(12, 1fr);
    display: grid;
  }

  section > * {
    margin: 0;
  }

  .about * {
    grid-column: 3 / span 8;
  }

  .our-focus-areas h2, .our-focus-areas .serif-large {
    grid-column: span 12;
  }

  .our-focus-areas div {
    grid-column: span 8;
  }

  .people {
    grid-gap: 48px;
    grid-template-columns: repeat(12, 1fr);
    display: grid;
  }

  .people > * {
    margin: 0;
  }

  .people h2 {
    grid-column: span 12;
  }

  .people .person {
    grid-column: span 4;
  }

  .people .staff {
    grid-column: span 4;
    grid-template-columns: repeat(4, 1fr);
    display: grid;
  }

  .people .staff > * {
    margin: 0;
  }

  .people .board {
    grid-column: 5 / span 8;
    grid-template-columns: repeat(8, 1fr);
    display: grid;
  }

  .people .board > * {
    margin: 0;
  }

  .people .board h3 {
    grid-column: span 8;
  }

  footer {
    grid-gap: 48px;
    grid-template-columns: repeat(12, 1fr);
    display: grid;
  }

  footer > * {
    margin: 0;
  }

  footer h2 {
    grid-column: span 2;
  }

  footer address {
    grid-column: 3 / span 4;
  }

  footer .contact {
    grid-column: 7 / span 4;
  }
}

body #mc_embed_signup {
  letter-spacing: 1px;
  background: none;
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-style: normal;
  font-weight: 600;
}

body #mc_embed_signup form {
  text-align: left;
  margin-top: 2em;
  padding: 0;
}

@media screen and (min-width: 860px) {
  body #mc_embed_signup form {
    margin-top: 0;
  }
}

body #mc_embed_signup form input.email, body #mc_embed_signup form .button {
  height: 48px;
  border-radius: 0;
}

body #mc_embed_signup form .button {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  background-color: #18514d;
  border: 1px solid #18514d;
  padding: 0 22px;
  font-size: 1em;
  font-weight: bold;
  transition: none;
}

body #mc_embed_signup form .button:hover {
  color: #18514d;
  background-color: #fff;
  border-color: #18514d;
}

@media screen and (min-width: 860px) {
  body #mc_embed_signup form .button {
    margin-left: 15px;
  }
}

.meet {
  text-transform: uppercase;
  font-weight: bold;
}

/*# sourceMappingURL=index.6866c4fb.css.map */
